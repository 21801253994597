// PARCELS SKIN

@import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');
@import url('https://use.typekit.net/heo4fnt.css');

@font-face {
  font-family: 'PSFont';
  src: url('./assets/fonts/font.woff2') format('woff2'), url('./assets/fonts/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

$font-family-heading: 'PSFont', 'Times New Roman', Times, serif;
$font-family-copy: 'Lato', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
$font-family-parcels25: 'new-science', serif;

.preview-image {
  visibility: hidden;
}

body {
  font-family: $font-family-copy;
  color: #2b2b2b;
}

#root {
  padding-top: 6rem;
  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
  }
}
body.product-listing-page {
  #root {
    padding-top: 0rem;
    @include media-breakpoint-down(sm) {
      padding-top: 0rem;
    }
  }
}

h1,
h2,
.btn,
legend,
.modal-title,
#footer h5,
.loading-spinner-content {
  font-family: $font-family-heading;
  //text-transform: uppercase;
  letter-spacing: 2px;
}

//PARCELS 25 PAGES

body.passwordtunnel-page {
  #footer {
    background-color: $white !important;
  }
  input {
    padding: 0.75rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    border-color: transparent;
  }
  #main-content {
    height: auto !important;
  }

  .teaserlogo {
    max-width: 500px;
    width: 80vw;
  }

  .navbar {
    background-color: $white !important;
  }

  h1,
  h2,
  .btn,
  legend,
  .modal-title,
  #footer h5,
  .loading-spinner-content {
    font-family: $font-family-parcels25;
    color: $parcels-grey !important;
  }

  .btn.btn-primary {
    color: $white !important;
  }

  #main::before {
    background: url(./assets/images/parcels25_bg.jpg);
    content: ' ';
    display: block;
    background-position: center center;
    // background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: -10;
  }

  // NIGIRI

  .nigiri.card {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $parcels-grey;
    font-family: $font-family-parcels25 !important;
    .nigiri-content {
      padding: 1.25rem 1.25rem;
      letter-spacing: 1px;
      margin-top: 0.2rem;
      //color: $text-color;
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h4-font-size;
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      //text-transform: uppercase;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      transform: rotate(2.5deg);
      font-size: 0.8em;
    }
    .title {
      font-size: 0.6em;
      margin-bottom: 0;
    }
    .subtitle {
      font-size: 0.5em;
      margin-bottom: 0;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-size: $h3-font-size !important;
      color: $parcels-grey;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
  }

  #footer {
    background-color: transparent;
    color: #484848;
    a {
      color: #484848;
      text-decoration: underline;
    }
  }
  #main-content {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// NAV

// .navbar-light {
//   background-color: rgba($color: #fff, $alpha: 0.9) !important;
//   //backdrop-filter: blur(10px) saturate(120%);
//   //better one
//   //background: linear-gradient(-180deg, rgba($white, 0.9) 0%, rgba($white, 0) 100%);
// }

// NAVBAR APPEAR

.navbar-light {
  background: transparent !important;
  transition: background 0.2s;
  .navbar-nav .nav-link {
    color: $parcels-orange !important;
  }
  .navbar-nav .nav-link-primary {
    background-color: #ff7345 !important;
    color: #fff !important;
    //font-size: $h4-font-size;
    font-weight: 900;
    &:hover {
      transform: scaleX(-1);
    }
  }
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: rgba($color: $white, $alpha: 0.9) !important;
    .navbar-nav .nav-link {
      color: $primary !important;
    }
    .navbar-nav .nav-link-primary {
      color: #fff !important;
    }
  }
}

.product-listing-page .navbar-brand {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.1s;
  transition-delay: 0;
}

body[data-scrolled] {
  .navbar-brand {
    opacity: 1;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}

#header .navbar .navbar-brand {
  img {
    height: 4rem;
    max-height: 4rem;
    @include media-breakpoint-down(md) {
      height: 2rem;
      max-height: 2rem;
    }
    &:hover {
      filter: opacity(0.66);
    }
  }
}

.navbar .nav-item {
  font-family: $font-family-heading;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.navbar .hamburger {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  top: 6.5rem;
  @include media-breakpoint-down(sm) {
    top: 5rem;
  }
  //background-color: $gray-900;
  //box-shadow: inset 0 20px 15px -20px rgba(0,0,0,.15);
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
      body[data-scrolled] & {
        color: #fff !important;
      }
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: #fff, $alpha: 0.9) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki.card {
  .card-title {
    font-family: $font-family-heading;
    font-size: $h4-font-size;
    justify-content: start;
    text-align: center;
    letter-spacing: 2px;
    .title {
      font-weight: 400;
      margin-bottom: 5px;
    }
    .sub-title {
      font-size: 0.8rem;
      @extend .mb-2;
    }
    .category {
      font-family: $font-family-copy;
    }
  }
  .maki-img {
    border: 1px solid $parcels-orange;
  }
  &:hover {
    //opacity: 0.8;
  }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-title {
    text-align: left;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $parcels-orange;
  &:hover,
  &:focus {
    color: lighten($parcels-orange, 0.2);
  }
}

.carousel-item-content img {
  border: 1px solid $parcels-orange;
}

.ticket-detail {
  .carousel-item-content img {
    border: 0;
  }
}

.blurry-background-image {
  background-color: transparent;
}

.blurry-background-image:before {
  background-image: none;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    h1 {
      margin-top: map-get($spacers, 5) !important;
    }
    h1,
    form {
      text-align: center;
    }
  }
}

// FOOTER

.footer-image-mascot {
  width: 150px;
}

#footer {
  background-color: $parcels-orange;
  min-height: 5vh;
  color: $white;
  a {
    color: $white;
  }
  //border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 6rem;
  // 3491 = "sale" tag
  .maki.tag-id-6622 {
    &::after {
      //@extend .rounded-circle;
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: -6px;
      right: 16px;
      //transform: translate(-50%, -50%);
      //transform: rotate(5deg);
      width: 80px;
      height: 80px;
      background: url('./assets/images/sale.svg') no-repeat center center;
      background-size: contain;
    }
    &:hover {
      &::after {
        opacity: 0.8;
      }
    }
  }
}

.hero-slider {
  margin-bottom: 0;
}

.hero-image {
  background-image: url('./assets/images/head.gif');
  background-position: center center;
  background-size: cover;
  height: 75vh;
  @include media-breakpoint-down(md) {
    height: 50vh;
  }
}

.hero-image-overlay {
  width: 50vw;
  opacity: 1;
  transition: opacity 0.2s;
  @include media-breakpoint-down(md) {
    width: 80vw;
  }
  body[data-scrolled] & {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.hero-image-mascot {
  width: 250px;
  margin-top: -130px;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

// SPECIALS

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.marquee {
  width: 100vw;
  height: 80px;
  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 80px;

  position: absolute;
  overflow: hidden;

  animation: marquee 20s linear infinite;
}

.marquee span {
  float: left;
  font-size: $h1-font-size * 2;
  font-family: $font-family-heading;
  color: $parcels-orange;
  text-transform: uppercase;
  letter-spacing: 2px;
  &:hover {
    transform: scaleX(-1);
  }
  //width: 50%;
}

.aussie-preorder a,
.aussie-preorder p {
  background-color: $parcels-orange;
  border: 1px solid $parcels-orange;
  color: #fff;
  @extend .p-3;
  display: block;
  font-size: $h3-font-size;
  @include media-breakpoint-down(md) {
    font-size: $h5-font-size;
  }
  text-align: center;
  font-family: $font-family-heading;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
    //opacity: 0.8;
  }
  &.outline {
    background-color: transparent;
    color: $parcels-orange;
  }
}

// TICKETS

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 1px solid $parcels-orange;
  font-family: 'PSFont', $font-family-sans-serif !important;
  .nigiri-content {
    padding: 1.25rem 1.25rem;
    letter-spacing: 1px;
    margin-top: 0.2rem;
    //color: $text-color;
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    //text-transform: uppercase;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-size: $h3-font-size !important;
    color: $parcels-orange;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

// TICKET DETAIL

.ticket-detail {
  .card.article-title {
    background-color: transparent;
    border: 0;
    letter-spacing: 1px;
  }
  .product-info-bar {
    box-shadow: none;
    .navbar-text {
      font-family: 'PSFont', $font-family-sans-serif !important;
      letter-spacing: 1px;
      .text-truncate {
        white-space: normal;
      }
    }
  }
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// LANDING

body.landing-page {
  background: url('./assets/images/backpo.jpg');
  background-size: cover;
  background-attachment: fixed;
  letter-spacing: 0 !important;
  text-transform: uppercase;
  $pspurple: #643cc8;

  .intro {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3rem;
  }

  #product-listing {
    padding-top: 0;
  }

  img.logo {
    width: 200px;
    -webkit-filter: drop-shadow(5px 0px 15px rgba($black, 0.5));
    filter: drop-shadow(5px 0px 15px rgba($black, 0.5));
  }

  img.typo {
    -webkit-filter: drop-shadow(5px 0px 15px rgba($black, 0.5));
    filter: drop-shadow(5px 0px 15px rgba($black, 0.5));
    max-width: 90%;
    @include media-breakpoint-up(md) {
      max-width: 50%;
    }
  }

  a.continue {
    background-color: $pspurple;
    padding: 1em;
    color: $white;
    font-size: 1.4em;
    font-weight: 800;
    box-shadow: $box-shadow-lg;
    &.aus {
      //background-color: #5cc63d;
      font-weight: normal;
    }
    &:hover {
      text-decoration: none;
      transform: scale(1.05);
    }
  }

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  .upline,
  .card-title {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif !important;
    letter-spacing: 0 !important;
    font-weight: 700;
  }

  h2.preordernow {
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    small {
      font-weight: 400;
      font-size: 0.8em;
      display: block;
    }
  }

  #header {
    display: none;
  }
  #root {
    padding-top: 0;
  }
  #footer {
    background-color: transparent;
  }

  // MAKI

  .maki {
    background-color: $white;
    box-shadow: $box-shadow-lg;
    //border: 7px solid $black
  }

  .maki.card .maki-img {
    border: 0;
  }

  .maki.card .card-title .title {
    color: $pspurple;
    text-transform: uppercase;
    font-weight: 700;
    font-size: $h4-font-size;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  .maki.card .card-title .price {
    font-weight: 400;
    font-size: 0.8em;
  }
}

// BANNER

.psbanner {
  background-color: #000;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  a {
    color: #fff;
  }
  font-weight: bold;
  position: fixed;
  width: 100%;
  z-index: 100;
}
